@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap");

#particles canvas {
  z-index: -1;
}

.card {
  perspective: 1000px;
}

.card-container {
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card:hover .card-container {
  transform: rotateY(180deg);
}

.card-face {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
}

.front-face {
  transform: rotateY(0deg);
}

.back-face {
  transform: rotateY(180deg);
}
